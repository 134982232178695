class LazyVideo {
  constructor (element) {
    this.isInitiated = false;
    this.isPlaying = false;
    this.isCreated = false;
    this.youtubeContainer = $(element);
    this.playerId = 'yt-player-' + this.youtubeContainer.parent().attr('data-content-uid');
    this.playButton = this.youtubeContainer.find('.play-button');
    this.setupPrivacyChangeEvent();
    this.initIfApproved();
  }

  setupPrivacyChangeEvent () {
    const that = this;
    this.youtubeContainer.on('privacychange', function () {
      that.initIfApproved();
    });
  }

  initIfApproved () {
    if (this.isContentApproved() && !this.isInitiated) {
      this.init();
    }
  }

  isContentApproved () {
    return this.youtubeContainer.attr('data-privacy-content-approved') === 'approved';
  }

  init () {
    this.isInitiated = true;
    this.youtubeId = $(this.youtubeContainer).attr('data-embed');
    this.title = $(this.youtubeContainer).attr('data-title');
    this.createIframe();
    this.setupScript();
    this.loadImage();
    this.setupListener();
  }

  /**
   * Tries to load the YouTube API script in case it was not before
   */
  setupScript () {
    const scriptUrl = 'https://www.youtube.com/iframe_api';
    let scriptElement = $(`script[src="${scriptUrl}"]`);

    if (scriptElement.length == 0) {
      scriptElement = $('<script>', {
        'src': scriptUrl,
      }).insertBefore($('script').first());
    }
  }

  /**
   * Sets up listeners for automatic pausing of playback caused by certain events
   */
  setupListener () {
    this.playButton.on('click keypress', (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (e.type === 'click' || (e.type === 'keypress' && (e.keyCode === 32 || e.keyCode === 13))) {
        this.createVideo();
      }
    });
    this.youtubeContainer.on('click keypress', (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (e.type === 'click' || (e.type === 'keypress' && (e.keyCode === 32 || e.keyCode === 13))) {
        this.createVideo();
      }
    });

    $('.nav-button').on('click', () => this.pauseVideo());
    $('.accessibility-navigation').on('click', () => this.pauseVideo());
    $('button').on('click', () => this.pauseVideo());
    $('video').on('play', () => this.pauseVideo());
    $('.youtube-lazy').not(this.youtubeContainer).on('play', () => this.pauseVideo());
  }

  /**
   * Creates the IFrame element of the player without appending in to the document
   */
  createIframe () {
    this.iframe = $('<iframe>', {
      'frameborder': '0',
      'allowfullscreen': '',
      'class': 'embed-responsive-item',
      'title': this.title,
      'id': this.playerId,
      'src': `https://www.youtube.com/embed/${this.youtubeId}?enablejsapi=1&version=3&playerapiid=ytplayer&autoplay=1`,
    });
  }

  /**
   * Create and append video container
   */
  createVideo () {
    this.hidePlayButton();
    this.isCreated = true;
    this.youtubeContainer.innerHTML = '';
    this.youtubeContainer.append(this.iframe);

    if (!this.checkApi()) {
      // keep checking until iframe_api finished loading
      this.ytApiCheck = setInterval(() => {
        if (this.checkApi()) {
          clearInterval(this.ytApiCheck);
        }
      }, 100);
    }

    const container = this.youtubeContainer;
    const thisFunction = this;
    const videoPos = container.offset().top;

    // stop playing on scrolling
    $(window).on('scroll', function () {
      var containerHeight = container.height();

      if ($(this).scrollTop() >= (videoPos + containerHeight) || $(this).scrollTop() <= (videoPos + containerHeight)) {
        thisFunction.pauseVideo();
      }
    });
  }

  /**
   * Check if the YouTube API has loaded
   */
  checkApi () {
    if (YT !== undefined) {
      this.player = new YT.Player(this.playerId, {
        events: {
          'onReady': (event) => this.onPlayerReady(event),
          'onStateChange': (event) => this.onStateChange(event),
        }
      });

      return true;
    }

    return false;
  }

  /**
   * Fired when a YouTube player loaded
   */
  onPlayerReady (event) {
    this.isPlaying = this.player.getPlayerState() != 2;
    this.youtubeContainer.trigger(this.isPlaying ? 'play' : 'pause');
  }

  /**
   * Fired when the state of a YouTube player changed
   */
  onStateChange (event) {
    if (event.data == 1) { // playing
      this.isPlaying = true;
      this.youtubeContainer.trigger('play');
    } else if (event.data == 2) {// paused
      this.isPlaying = false;
      this.youtubeContainer.trigger('pause');
    }
  }

  /**
   * Hides the play button in the player container
   */
  hidePlayButton () {
    this.playButton.attr('aria-expanded', 'true');
    this.playButton.css('z-index', '-1');
  }

  /**
   * Pauses playback of the player
   */
  pauseVideo () {
    if (this.isCreated) {
      this.iframe[0].contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
    }
  }

  /**
   * Starts playback of the player
   */
  playVideo () {
    if (this.isCreated) {
      this.iframe[0].contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
    }
  }

  /**
   * Lazy Load Preview Thumbnails
   */
  loadImage () {
    let customPoster = this.youtubeContainer.attr('data-poster');
    this.youtubeContainer.attr('title', this.title);

    if (customPoster) {
        this.youtubeContainer.addClass('custom-poster-background');
        this.youtubeContainer.css('background-image', `url(${customPoster})`);
    } else {
        const imageSource = `https://img.youtube.com/vi/${this.youtubeId}/hqdefault.jpg`;
        const image = new Image();
        image.src = imageSource;
        image.setAttribute('alt', this.title);
        image.addEventListener('load', () => {
          this.youtubeContainer.append(image);
        });
    }
  }
}

export default LazyVideo;
